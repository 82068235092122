/* AdminRoutingMixin.ts */

import { Component, Mixins } from 'vue-property-decorator';
import { RoutingMixin } from "@/mixins";
import { Route } from 'vue-router';
import * as Routes from '@/../types/constants/admin.routes';

/**
 * Provides routing routines
 */
@Component
export class AdminRoutingMixin extends Mixins(RoutingMixin) {
	RouteToAdminEvents(): Partial<Route> {
		return { name: Routes.BAEventAdmin };
	}
	gotoAdminEvents() {
		this.gotoRoute(this.RouteToAdminEvents())
	}
}
