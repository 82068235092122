// PaginatedTableMixin
//
// Mixin for pages that display a table that may contain many entries
// the data entries will be paginated in the backend
// Users require the following
// - import DataTableHeader, RepositoryQuery, QueryOptions
//		import { DataTableHeader } from 'vuetify';
// 		import { RepositoryQuery, QueryOptions, PaginatedResponse } from '@/../types';
// - create 'TableHeaders' getter function
// 		get TableHeaders(): Array<DataTableHeader<any>> {
//			let headers: Array<DataTableHeader<any>> = [
//				{ text: 'Name', value: 'name', sortable: false },
//			];
//			if( this.IsLargeScreen ) {
//				headers.push({text: '', value: 'actions', sortable: false });
//				headers.push({text: '', value: 'data-table-expand', sortable: false});
//			}
//			return headers;
//		}
// - update 'tableOptions' if different settings are required. do this on `mounted()`
//		this.tableOptions.sortBy = ['lastName'];
//		this.tableOptions.sortDesc = [true];
// - add 'search' property
//		search: string = '';
// - update 'localForagePersistFields' if different fields need to be recalled. do this on `mounted()` 
// 		this.localForagePersistFields = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 10]];
// - override `loadTable` function
//		async loadTable() {
// 			this.isLoading = true;
// 			this.isLoaded = false;
//			const query: RepositoryQuery<SomeModel> = this.TableQuery<SomeModel>(this.search, ['title', 'notes']);;
//			query.$match = {...query.$match, ...{ key: value }};
//			const options: QueryOptions = this.TableQueryOptions;
//			const response: PaginatedResponse<SomeModel> = await someApi.queryAll(query, options);
//			this.dataItems = response.docs;
//			this.dataItemsCount = response.total;
//			this.isLoaded = true;
//			this.isLoading = false;
//		}
// - update `TableLoading` function, if necessary
// 		get TableLoading(): boolean {
// 			return this.isLoading;
// 		}
// - update `PageLoading` function, if necessary
// 		get PageLoading(): boolean {
// 			return this.TableLoading || this.isLoading;
// 		}
// - create `TotalItems` function to return total number of items in database, if override is necessary
// 		get TotalItems(): number {
// 			return this.dataItemsCount;
// 		}
// - create a table:
//		<LoadingWait v-if="TableLoading" text="Loading Table" />
//      <v-data-table v-else dense hide-default-footer single-expand :show-expand="IsLargeScreen"
//          :headers="TableHeaders"
//          :items="ARRAY OF ITEMS TO DISPLAY"
//          :loading="TableLoading"
//          :server-items-length="TotalItems"
//          :options.sync="tableOptions"
//      >
//		</v-data-table>
// - add 'search' box
// 		<template v-slot:top>
//			<div class="d-flex">
// 				<v-text-field outlined dense clearable
// 					label="Search"
// 					:prepend-inner-icon="BAIcons.search"
//			 		v-model="search"
//			 		@input="updateSearch"
//			 	/>
//			 	<v-spacer />
//			 	<div class="d-flex align-start">
//			 		<div>Found <b>{{ TotalItems }}</b> items</div>
//			 	</div>
//			</div>
//		</template>
// - display expanded item (OPTIONAL)
//		<template v-slot:expanded-item="{ headers, item }">
//			<td :colspan="headers.length">
//				{{ item }}
//			</td>
//		</template>
// - create a pagination
// 		<template v-slot:footer="{}">
// 			<div class="d-flex flex-row py-2 px-4">
// 				<div v-if="IsLargeScreen" class="d-flex flex-row">
// 				<div class="d-flex align-center mr-2">Rows per page</div>
// 				<v-select outlined dense hide-details style="max-width: 100px"
// 					:items="RowsPerPageItems"
// 					item-text="name"
// 					item-value="value"
// 					v-model="tableOptions.itemsPerPage"
// 					@change="updateSearch"
// 				/>
// 				</div>
// 				<v-spacer />
// 				<v-pagination v-if="!TableLoading"
// 					v-model="tableOptions.page"
// 					@input="updateTable"
// 					:length="TotalPages"
// 					:total-visible="IsLargeScreen? '8' : '5'"
// 				/>
// 			</div>
// 		</template>
// - add item displays for table items
//		<template v-slot:item.actions="{item}">
//		</template>

import { Component, Mixins } from 'vue-property-decorator';
import { DebounceMixin, LocalForageMixin } from '@/mixins';
import { DataOptions } from 'vuetify';
import { RepositoryQuery, QueryOptions } from '@/../types/interfaces';

@Component
export class PaginatedTableMixin extends Mixins(DebounceMixin, LocalForageMixin) {
	localForagePersistFields: Array<string | [string, any]> = [['search', ''],['tableOptions.page', 1],['tableOptions.itemsPerPage', 10]];
	tableOptions: DataOptions = {
		page: 1,
		itemsPerPage: 10,
		sortBy: ['none'],
		sortDesc: [false],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};

	get HasItems(): boolean {
		return this.TotalItems > 0;
	}
	get TotalItems(): number {
		// mixin user can override this function if something other than dataItemsCount counts the total items
		return this.dataItemsCount;
	}
	get TotalPages(): number {
		return Math.ceil(this.TotalItems / this.tableOptions.itemsPerPage);
	}

	mounted() {
		this.updateSearch();
	}

	dataItems: Array<any> = [];
	dataItemsCount: number = 0;
	search: string = '';
	isLoading: boolean = false;
	isLoaded: boolean = false;
	tableUpdatePending: boolean = false;
	private debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try {
				this.tableUpdatePending = true;
				await this.updateTable();
			} catch (e) {
					console.error("Failed to update table");
			} finally {
				this.tableUpdatePending = false;
			}
		}, 500);
	}

	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		await this.loadTable();
	}

	async refreshTable() {
		this.persistField(this.LocalForagePersistFieldKeys);
	}
	async resetTable() {
		this.tableOptions.page = 1;
		this.refreshTable();
	}
	async updateFilter(): Promise<void>{
		this.tableOptions.page = 1;
		await this.updateTable();
	}
	public async updateSearch() {
		this.tableOptions.page = 1;
		this.debounceUpdateTable();
	}
	async loadTable(): Promise<void>{
        // mixin user must override this function
    }
	get TableLoading(): boolean {
		// mixin user can override this function if something other than isLoading is used
		return this.isLoading;
	}
	get PageLoading(): boolean {
		// mixin user can override this function if something other than TableLoading is used
		return this.TableLoading;
	}

    TableQuery<T>(search, fields): RepositoryQuery<T> {
        const query: RepositoryQuery<T> = {search, fields};
        return query;
    }
    get TableQueryOptions(): QueryOptions {
        var options: QueryOptions = { 
            page: this.tableOptions.page,
            limitPerPage: this.tableOptions.itemsPerPage,
        };
        if( this.tableOptions.sortBy.length > 0 ) {
            options.sort = {
                fields: this.tableOptions.sortBy.map((field, index) => {
                    return {
                        field: field,
                        desc: this.tableOptions.sortDesc[index],
                    };
                }),
            };
        }

        return options;
    }

    get RowsPerPageItems() {
        return [
            { name: '10', value: 10 },
            { name: '25', value: 25 },
            { name: '100', value: 100 },
        ]
    }
}
