import { getEnumValues } from './get-enum-values';
export enum AgeGroup {
	U13 = 'U13',
	U14 = 'U14',
	U15 = 'U15',
	U16 = 'U16',
	U17 = 'U17',
	U18 = 'U18',
	U19 = 'U19',
	U20 = 'U20',
	O20 = 'O20',
}
export const AgeGroupValues = getEnumValues<AgeGroup>(AgeGroup);
export enum Under13AgeGroup {
	U6 = 'U6',
	U7 = 'U7',
	U8 = 'U8',
	U9 = 'U9',
	U10 = 'U10',
	U11 = 'U11',
	U12 = 'U12'
}
export const UnderU13AgeGroupValues = getEnumValues<Under13AgeGroup>(Under13AgeGroup);
export const AllAgeGroupValues = [...AgeGroupValues, ...UnderU13AgeGroupValues];


const ageGroupOrder = {
	U13: 0,
	U14: 1,
	U15: 2,
	U16: 3,
	U17: 4,
	U18: 5,
	U19: 6,
	U20: 7,
	O20: 8,
};
export const compareAgeGroup = (a: AgeGroup, b: AgeGroup): number => ageGroupOrder[a] - ageGroupOrder[b]

export function StringToPlayingLevel(s: string): AgeGroup | Under13AgeGroup | null {
	if( s.includes('U13') ) return AgeGroup.U13;
	if( s.includes('U14') ) return AgeGroup.U14;
	if( s.includes('U15') ) return AgeGroup.U15;
	if( s.includes('U16') ) return AgeGroup.U16;
	if( s.includes('U17') ) return AgeGroup.U17;
	if( s.includes('U18') ) return AgeGroup.U18;
	if( s.includes('U19') ) return AgeGroup.U19;
	if( s.includes('U20') ) return AgeGroup.U20;
	if( s.includes('O20') ) return AgeGroup.O20;
	return null;
}

export function BirthdayToPlayingLevel(birthDate: Date): AgeGroup | Under13AgeGroup | null {
	if( !birthDate ) return null;
	if( birthDate === null ) return null;

	const today: Date = new Date();
	const years = today.getFullYear() - birthDate.getFullYear();
	return StringToPlayingLevel(`U${years}`);
}