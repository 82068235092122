// color.ts

export const colorWhite: string = '#FFFFFF';
export const colorBlack: string = '#000000';

export function isCloserToBlackOrWhite(hex: string, opposite: boolean = false): string {
    hex = hex.slice(0,7);
    // Validate hex color code
    if (!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(hex)) {
        throw new Error("Invalid hex color code");
    }

    // Expand shorthand hex code (e.g., #123 -> #112233)
    if (hex.length === 4) {
        hex = `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`;
    }

    // Extract RGB components
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Calculate brightness using the luminance formula
    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

    // Compare brightness to 128
    
    if( opposite ) return ( brightness < 128 )?  colorWhite : colorBlack;
    return ( brightness < 128 )? colorBlack : colorWhite;
}    