import { AthleteAssessmentDataModel, AthleteProfileModel, BAEventModel, TeamJersey, TeamModel } from '@/models';
import { FrontEndModel } from '../FrontEndModel';
import { isCloserToBlackOrWhite } from '@/helpers'
import { isEmpty, isNotEmpty } from '@/pipes';
import { OrganizationModel } from '../organization/OrganizationModel';

class Trial {
	trial: number;

	constructor(trial) {
		this.trial = trial;
	}
}
export class Sprint extends Trial {
	tenMeters: number;
	twentyMeters: number;
	thirtyFiveMeters: number;

	get isComplete(): boolean {
		return( isNotEmpty(this.tenMeters) && isNotEmpty(this.twentyMeters) && isNotEmpty(this.thirtyFiveMeters) );
	}
}

class Jump extends Trial {
	height: number;
}
export class JumpDJ extends Jump {
	contactTime: number;

	get Agility(): string {
		if( isEmpty(this.contactTime) ) return '';
		if( isEmpty(this.height) ) return '';
		const agility = this.height / this.contactTime / 100.0;
		return agility.toFixed(2);
	}
	get isComplete(): boolean {
		return( isNotEmpty(this.height) && isNotEmpty(this.contactTime) );
	}	
}
export class JumpCMJ extends Jump {
	Power(mass: number): string {
		if( isEmpty(mass) ) return '';
		if( isEmpty(this.height) ) return '';
		const power = 60.7 * mass + 45.3 * this.height - 2055;
		return power.toFixed(1);
	}
	get isComplete(): boolean {
		return( isNotEmpty(this.height) );
	}	
}

export class YoYo extends Trial {
	stage: number;

	get Distance(): string {
		if( isEmpty(this.stage) ) return '';
		const distance = this.stage < 14? 120 * this.stage - 1220 : 320 * this.stage - 4000;
		return distance.toFixed(1);
	}
	get AerobicVelocity(): string {
		if( isEmpty(this.stage) ) return '';
		const av = 0.5 * this.stage + 7.2;
		return av.toFixed(1);
	}
	get isComplete(): boolean {
		return( isNotEmpty(this.stage) );
	}	
}

export class EventAssessmentDataModel extends FrontEndModel {
	athleteId: string;							// id of AthleteProfileModel
	eventId: string;							// id of BAEventModel
	assessmentId: string;						// id of AthleteAssessmentDataModel

	checkedIn: boolean = false;
	group: string = "";							// determined by event ticket
	idcolor: string = "";						// identification jersey color
	idnumber: string = "";						// identification number
	notes: string = "";
	tags: string = "";							// used for searching

	sprints: Array<Sprint>;						// sprint trials
	jumpsDJ: Array<JumpDJ>;						// drop jump trials
	jumpsCMJ: Array<JumpCMJ>;					// counter movement trials
	yoyos: Array<YoYo>;							// yoyo intermittent recovery test trials
	bestSprint: number;							// computed
	bestJumpDJ: number;							// computed
	bestJumpCMJ: number;						// computed
	bestYoYo: number;							// computed

	athlete: AthleteProfileModel;				// only used by front end
	event: BAEventModel;						// only used by front end
	physical: AthleteAssessmentDataModel;		// only used by front end
	team: TeamModel;							// only used by front end
	organization: OrganizationModel;			// only used by front end
	changesPending: boolean = false;			// only used by front end
	currentSprint: number = 1;					// only used by front end
	currentJumpDJ: number = 1;					// only used by front end
	currentJumpCMJ: number = 1;					// only used by front end
	currentYoYo: number = 1;					// only used by front end

	constructor() {
		super();
		this.initialize();
	}
	async initialize() {
		if( isEmpty(this.sprints) ) this.sprints = [new Sprint(1), new Sprint(2), new Sprint(3)];
		else this.sprints = this.sprints.map(t => {
			const newSprint = new Sprint(t.trial);
			Object.assign(newSprint, t);
			return newSprint;
		})
		if( isEmpty(this.bestSprint) ) this.bestSprint = 0;
		
		if( isEmpty(this.jumpsDJ) ) this.jumpsDJ = [new JumpDJ(1), new JumpDJ(2)];
		else this.jumpsDJ = this.jumpsDJ.map(t => {
			const newJumpDJ = new JumpDJ(t.trial);
			Object.assign(newJumpDJ, t);
			return newJumpDJ;
		})
		if( isEmpty(this.bestJumpDJ) ) this.bestJumpDJ = 0;

		if( isEmpty(this.jumpsCMJ) ) this.jumpsCMJ = [new JumpCMJ(1), new JumpCMJ(2)];
		else this.jumpsCMJ = this.jumpsCMJ.map(t => {
			const newJumpCMJ = new JumpCMJ(t.trial);
			Object.assign(newJumpCMJ, t);
			return newJumpCMJ;
		})
		if( isEmpty(this.bestJumpCMJ) ) this.bestJumpCMJ = 0;

		if( isEmpty(this.yoyos) ) this.yoyos = [new YoYo(1)];
		else this.yoyos = this.yoyos.map(t => {
			const newYoYo = new YoYo(t.trial);
			Object.assign(newYoYo, t);
			return newYoYo;
		})
		if( isEmpty(this.bestYoYo) ) this.bestYoYo = 0;
	}
	get Jersey(): TeamJersey {
		if( isNotEmpty(this.idcolor) && isNotEmpty(this.idnumber) ) {
			return {
				color: this.idcolor,
				accent: this.idcolor,
				number: isCloserToBlackOrWhite(this.idcolor, true),
				label: 'Event Jersey',
			}
		}
		return this.athlete?.CurrentTeam?.jerseyPrimary;
	}
	get FullName(): string {
		return this.athlete?.FullName;
	}
	get Email(): string {
		return this.athlete?.Email;
	}
	get hasCheckinData(): boolean {
		return( this.checkedIn && this.hasMeasurements );
	}
	get hasMeasurements(): boolean {
		if( isEmpty(this.physical) ) return false;
		return( this.hasWeight && this.hasHeight );
	}
	get hasWeight(): boolean {
		if( isEmpty(this.physical) ) return false;
		return isNotEmpty(this.physical.mass);
	}
	get hasHeight(): boolean {
		if( isEmpty(this.physical) ) return false;
		return isNotEmpty(this.physical.standingHeight) && isNotEmpty(this.physical.sittingHeightWithBox);
	}
	get isNotReadyForCMJ(): boolean {
		if( isEmpty(this.physical) ) return false;
		return( !this.checkedIn || !this.hasWeight );
	}
	get isComplete(): boolean {
		return( this.HasBestSprint && this.HasBestJumpDJ && this.HasBestJumpCMJ && this.HasBestYoYo );
	}

	get hasSprint(): boolean {
		if( isEmpty(this.sprints) ) return false;
		for( const sprint of this.sprints)  {
			if( isNotEmpty(sprint.tenMeters) && isNotEmpty(sprint.twentyMeters) && isNotEmpty(sprint.thirtyFiveMeters) ) return true;
		}
		return false;
	}
	addSprint() {
		this.sprints.push(new Sprint(this.sprints.length + 1));
	}
	get CurrentSprint(): Sprint {
		return this.sprints.find(s => s.trial === this.currentSprint);
	}
	get HasBestSprint(): boolean {
		return this.bestSprint != 0;
	}
	get BestSprint(): Sprint {
		return this.sprints.find(s => s.trial === this.bestSprint);
	}
	isBestSprint(sprint: Sprint): boolean {
		if( isEmpty(sprint) ) return false;
		return sprint.trial === this.bestSprint;
	}
	async ComputeBestSprint() {
		this.bestSprint = 0;
		for( const sprint of this.sprints ) {
			if( isEmpty(this.BestSprint) && isNotEmpty(sprint.thirtyFiveMeters) ) this.bestSprint = sprint.trial;
			if( isNotEmpty(this.BestSprint) && (sprint.thirtyFiveMeters <= this.BestSprint.thirtyFiveMeters) ) this.bestSprint = sprint.trial;
		}

		if( isNotEmpty(this.BestSprint) ) {
			this.physical.tenMeterSprint = this.BestSprint.tenMeters;
			this.physical.twentyMeterSprint = this.BestSprint.twentyMeters;
			this.physical.thirtyFiveMeterSprint = this.BestSprint.thirtyFiveMeters;
			// compute best speed and acceleration
			this.physical.twentyToThirtyFiveMeterSplit = this.BestSprint.thirtyFiveMeters - this.BestSprint.twentyMeters;
			this.physical.computeAcceleration();
			this.physical.computeSpeed();
		} else {
			this.physical.tenMeterSprint = undefined;
			this.physical.twentyMeterSprint = undefined;
			this.physical.thirtyFiveMeterSprint = undefined;
			this.physical.twentyToThirtyFiveMeterSplit = undefined;
			this.physical.speed = undefined;
			this.physical.acceleration = undefined;
		}
	}
	get BestAcceleration(): string {
		return this.physical.Acceleration();
	}
	get BestSpeed(): string {
		return this.physical.Speed();
	}
	get Fastest35m(): string {
		if( isEmpty(this.physical.thirtyFiveMeterSprint) ) return ''
		return `${this.physical.ThirtyFiveMeters} s`
	}

	get hasJumpDJ() {
		if( isEmpty(this.jumpsDJ) ) return false;
		for( const jump of this.jumpsDJ ) {
			if( isNotEmpty(jump.height) && isNotEmpty(jump.contactTime) ) return true;
		}
		return false;
	}
	addJumpDJ() {
		this.jumpsDJ.push(new JumpDJ(this.jumpsDJ.length + 1));
	}
	get CurrentJumpDJ(): JumpDJ {
		return this.jumpsDJ.find(s => s.trial === this.currentJumpDJ);
	}
	get HasBestJumpDJ(): boolean {
		return this.bestJumpDJ != 0;
	}
	get BestJumpDJ(): JumpDJ {
		return this.jumpsDJ.find(jump => jump.trial === this.bestJumpDJ);
	}
	isBestJumpDJ(jump: JumpDJ): boolean {
		if( isEmpty(jump) ) return false;
		return jump.trial === this.bestJumpDJ;
	}
	get BestAgility(): string {
		return this.physical.Agility();
	}
	async ComputeBestJumpDJ() {
		this.bestJumpDJ = 0;
		for( const jump of this.jumpsDJ ) {
			if( isEmpty(this.BestJumpDJ) && isNotEmpty(jump.height) && isNotEmpty(jump.contactTime) ) this.bestJumpDJ = jump.trial;
			if( isNotEmpty(this.BestJumpDJ) && (jump.height > this.BestJumpDJ.height) ) this.bestJumpDJ = jump.trial;
		}

		if( isNotEmpty(this.BestJumpDJ) ) {
			this.physical.dropJumpHeight = this.BestJumpDJ.height;
			this.physical.dropJumpContactTime = this.BestJumpDJ.contactTime;
			// compute agility
			this.physical.computeAgility();
		} else {
			this.physical.dropJumpHeight = undefined;
			this.physical.dropJumpContactTime = undefined;
			this.physical.reactiveStrengthIndex = undefined;
		}
	}

	get hasJumpCMJ() {
		if( isEmpty(this.jumpsCMJ) ) return false;
		for( const jump of this.jumpsCMJ ) {
			if( isNotEmpty(jump.height) ) return true;
		}
		return false;
	}
	addJumpCMJ() {
		this.jumpsCMJ.push(new JumpCMJ(this.jumpsCMJ.length + 1));
	}
	get CurrentJumpCMJ(): JumpCMJ {
		return this.jumpsCMJ.find(s => s.trial === this.currentJumpCMJ);
	}
	get HasBestJumpCMJ(): boolean {
		return this.bestJumpCMJ != 0;
	}
	get BestJumpCMJ(): JumpCMJ {
		return this.jumpsCMJ.find(jump => jump.trial === this.bestJumpCMJ);
	}
	isBestJumpCMJ(jump: JumpCMJ): boolean {
		if( isEmpty(jump) ) return false;
		return jump.trial === this.bestJumpCMJ;
	}
	get BestPower(): string {
		return this.physical.Power();
	}
	async ComputeBestJumpCMJ() {
		this.bestJumpCMJ = 0;
		for( const jump of this.jumpsCMJ ) {
			if( isEmpty(this.BestJumpCMJ) && isNotEmpty(jump.height) ) this.bestJumpCMJ = jump.trial;
			if( isNotEmpty(this.BestJumpCMJ) && (jump.height > this.BestJumpCMJ.height) ) this.bestJumpCMJ = jump.trial;
		}

		if( isNotEmpty(this.BestJumpCMJ) ) {
			this.physical.counterMovementJumpHeight = this.BestJumpCMJ.height;
			// compute power
			this.physical.computePower();
		} else {
			this.physical.counterMovementJumpHeight = undefined;
			this.physical.power = undefined;
		}
	}

	get hasYoYo() {
		if( isEmpty(this.yoyos) ) return false;
		for( const yoyo of this.yoyos ) {
			if( isNotEmpty(yoyo.stage) ) return true;
		}
		return false;
	}
	addYoYo() {
		this.yoyos.push(new YoYo(this.yoyos.length + 1));
	}
	get CurrentYoYo(): YoYo {
		return this.yoyos.find(s => s.trial === this.currentYoYo);
	}
	get HasBestYoYo(): boolean {
		return this.bestYoYo != 0;
	}
	get BestYoYo(): YoYo {
		return this.yoyos.find(yoyo => yoyo.trial === this.bestYoYo);
	}
	isBestYoYo(yoyo: YoYo): boolean {
		if( isEmpty(yoyo) ) return false;
		return yoyo.trial === this.bestYoYo;
	}
	get BestYoYoDistance(): string {
		if( isEmpty(this.physical.yoyoIntermittentRecoveryTestDistance) ) return ''
		return `${this.physical.yoyoIntermittentRecoveryTestDistance} m`;
	}
	get BestRecovery(): string {
		return this.physical.Recovery();
	}
	async ComputeBestYoYo() {
		this.bestYoYo = 0;
		for( const yoyo of this.yoyos ) {
			if( isEmpty(this.BestYoYo) && isNotEmpty(yoyo.stage) ) this.bestYoYo = yoyo.trial;
			if( isNotEmpty(this.BestYoYo) && (yoyo.stage > this.BestYoYo.stage) ) this.bestYoYo = yoyo.trial;
		}

		if( isNotEmpty(this.BestYoYo) ) {
			this.physical.yoyoIntermittentRecoveryTestStage = this.BestYoYo.stage;
			// compute recovery
			this.physical.computeRecovery();
		} else {
			this.physical.yoyoIntermittentRecoveryTestStage = undefined;
			this.physical.yoyoIntermittentRecoveryTestDistance = undefined;
			this.physical.maximalAerobicVelocity = undefined;
		}
	}

	Compute() {
		this.physical.boxHeight = 42;

		// legLength = standingHeight - sittingHeightWithBox
		if( isNotEmpty(this.physical.standingHeight) && isNotEmpty(this.physical.sittingHeightWithBox) ) 
			this.physical.legLength = this.physical.standingHeight - this.physical.sittingHeightWithBox;

		// trueSittingHeight = sittingHeightWithBox - boxHeight
		if( isNotEmpty(this.physical.sittingHeightWithBox) && isNotEmpty(this.physical.boxHeight) ) 
			this.physical.trueSittingHeight = this.physical.sittingHeightWithBox - this.physical.boxHeight;

		// legTrunk = (height - sittingHeight)*sittingHeight
		if( isNotEmpty(this.physical.standingHeight) && isNotEmpty(this.physical.trueSittingHeight) )
			this.physical.legTrunk = (this.physical.standingHeight - this.physical.trueSittingHeight) * this.physical.trueSittingHeight;

		// ageLeg = age*(height-sittingHeight)
		if( isNotEmpty(this.physical.age) && isNotEmpty(this.physical.standingHeight) && isNotEmpty(this.physical.trueSittingHeight) )
			this.physical.ageLeg = this.physical.age * (this.physical.standingHeight - this.physical.trueSittingHeight);

		// ageSittingHeight = age * sittingHeight
		if( isNotEmpty(this.physical.age) && isNotEmpty(this.physical.trueSittingHeight) )
			this.physical.ageSittingHeight = this.physical.age * this.physical.trueSittingHeight;

		// ageMass = age * mass
		if( isNotEmpty(this.physical.age) && isNotEmpty(this.physical.mass) )
			this.physical.ageMass = this.physical.age * this.physical.mass;

		// massHeightRatio = (mass/height)*100
		if( isNotEmpty(this.physical.mass) && isNotEmpty(this.physical.standingHeight) )
			this.physical.massHeightRatio = (this.physical.mass / this.physical.standingHeight) * 100.0;

		// bodyMassIndex = mass/((height/100)^2)
		if( isNotEmpty(this.physical.mass) && isNotEmpty(this.physical.standingHeight) )
			this.physical.bodyMassIndex = this.physical.mass / ((this.physical.standingHeight/100)^2);

		// maturityOffset = -9.236+(0.0002708* (legTrunk) )-(0.001663* (ageLeg) )+(0.007216* (ageSittingHeight) )+(0.02292* (massHeightRatio) )
		if( isNotEmpty(this.physical.legTrunk) && isNotEmpty(this.physical.ageLeg) && isNotEmpty(this.physical.ageSittingHeight) && isNotEmpty(this.physical.massHeightRatio) )
			this.physical.maturityOffset = -9.236+(0.0002708* (this.physical.legTrunk) )-(0.001663* (this.physical.ageLeg) )+(0.007216* (this.physical.ageSittingHeight) )+(0.02292* (this.physical.massHeightRatio) );

		// ageOfPeakHeightVelocity = age - maturityOffset
		if( isNotEmpty(this.physical.age) && isNotEmpty(this.physical.maturityOffset) )
			this.physical.ageOfPeakHeightVelocity = this.physical.age - this.physical.maturityOffset;
	}
	UpdateTags() {
		this.tags = `${this.FullName.toLowerCase()} ${this.team?.name.toLowerCase()} ${this.organization?.name.toLowerCase()} ${this.idnumber}`
	}
}

