/* AdminAuthMixin.ts */

import { Component, Vue } from 'vue-property-decorator';
import { AllAdminAppRoles, EventAdminAppRoles, UserAdminAppRoles } from '@/../types/constants/role-lists';
import { RoleName } from 'types';

@Component
export class AdminAuthMixin extends Vue {
	get UserAdminRoles(): Array<RoleName> {
		return UserAdminAppRoles;
	}
	get EventAdminRoles(): Array<RoleName> {
		return EventAdminAppRoles;
	}
	get AdminRoles(): Array<RoleName> {
		return AllAdminAppRoles;
	}
}
