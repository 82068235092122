
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';

@Component
export class ConfirmationDialog extends Mixins(VuetifyMixin){
	@Prop({ default: 500, type: Number }) width: number;
	@Prop({ default: false, type: Boolean }) hideTitle: boolean ;
	@Prop({ default: false, type: Boolean }) loading: boolean ;
	@Prop({ default: false, type: Boolean }) show: boolean ;
	input(show: boolean): void{ this.$emit('update:show', show); }

	@Prop({ default: null }) icon: string | null;
	@Prop({ default: "Are you sure?" }) titleText: string;
	@Prop({ default: "This action cannot be undone." }) bodyText: string;
	@Prop({ default: "Cancel" }) cancelText: string;
	@Prop({ default: "Confirm" }) confirmText: string;

	@Prop({ default: false, type: Boolean }) dark: boolean;
	@Prop({ default: undefined, type: String }) color: string;

	get Fullscreen(): boolean{
		return this.IsMobile;
	}

	confirm($event: Event): void{
		$event.preventDefault();
		this.$emit('confirm');
		this.input(false);
	}
	cancel(): void{
		this.$emit('cancel');
		this.input(false);
	}
}
export default ConfirmationDialog;
